import NewsletterSignup from '@components/newsletterSignup'

const NewsletterSignupSection = () => {
  return (
    <div className="text-center p-4 md:p-8 border-b-2 border-white bg-black text-white">
      <NewsletterSignup />
    </div>
  )
}

export default NewsletterSignupSection
