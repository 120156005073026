import Link from 'next/link'

// import { H2Heading } from '@components/typography'
import { ProductTile } from '@components/views/shop'

const ShopFeatured = ({ data }) => {
  return (
    <div className="border-t-2 border-foreground">
      <ShopLink />
      <div className="block sm:grid sm:grid-cols-2 lg:grid-cols-3">
        {data.map((product, i) => (
          <ProductTile key={`${product.handle}-${i}`} data={product} />
        ))}
      </div>
    </div>
  )
}

const ShopLink = () => {
  return (
    <Link
      href="/shop"
      scroll={false}
      className="block text-2xl bg-background text-foreground border-b-px border-foreground"
    >
      <span className="block p-2 text-2xl md:text-4xl">
        Merch that’ll make you say it in CAPSLOCK
      </span>
    </Link>
  )
}

export default ShopFeatured
