import Image from 'next/image'
import Link from 'next/link'

const IMAGE_SIZES = '(max-width: 1024px) 100vw, (min-width: 1024px) 66vw'

const PastEvents = ({ data }) => {
  const [post] = data
  const { title, handle, excerpt, image } = post

  return (
    <div>
      <div className="p-2 border-t-px bg-background text-foreground">
        <span className="text-2xl md:text-4xl">Last event</span>
      </div>
      <div className="border-t-px lg:flex">
        <div className="relative w-full h-52 lg:h-screen overflow-hidden">
          <Link href={`/blogs/events/${handle}`}>
            <Image
              alt={image.altText}
              src={image.url}
              // width={image.width}
              // height={image.height}
              fill
              className="object-cover"
              sizes={IMAGE_SIZES}
            />
          </Link>
        </div>
        <div className="p-2 grow shrink-0 w-full lg:max-w-xl border-t-px lg:border-l-px border-background">
          <Link href={`/blogs/events/${handle}`} scroll={false}>
            <div className="sticky top-16">
              <p className="text-xl pb-2">Last event</p>
              <p className="text-3xl lg:text-6xl lg:pb-4">{title}</p>
              <p className="text-xl">{excerpt}</p>
              <p className="block text-xl py-2 after:content-['_→']">
                Read more
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PastEvents
