import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

import MainLayout from '@components/layout/main'
import GlobalMetaTags from '@components/metaTags'
import CreativeCompass from '@components/projects/creativeCompass'
import AboutSection from '@components/sections/about/about'
import NewsletterSignupSection from '@components/sections/newsletterSignupSection'
import NextEvents from '@components/sections/nextEvents'
import PastEvents from '@components/sections/pastEvents'
import ShopFeatured from '@components/sections/shopFeatured'

import { useLenis } from '@lib/lenisContext'
import { getFeaturedProducts } from '@lib/shopify'
import {
  getEventPostPreviewsByBlogHandle,
  getEventsList,
} from '@lib/shopify/blog'
import { easeInOutExpo } from '@lib/utils'

// const ProjectsList = dynamic(
//   () => import('@components/sections/projectsList'),
//   { ssr: false }
// )

// const SovrumanaHero = dynamic(() => import('@components/sections/hero'), {
//   ssr: false,
// })

// const JumpToContentButton = dynamic(
//   () => import('@components/jumpToContentButton')
// )

export default function Homepage({
  featuredProducts,
  eventsList,
  pastEventsPostList,
}) {
  const router = useRouter()
  const lenis = useLenis()

  const scrollTo = useCallback(
    (immediate = false) => {
      let tid: NodeJS.Timeout
      const { hash } = new URL(
        router.asPath,
        `${window.location.protocol}//${window.location.hostname}`
      )
      const el = document.getElementById(hash.replace('#', ''))

      if (!el || !lenis) {
        if (tid) clearTimeout(tid)
        tid = setTimeout(() => scrollTo(false), 250)
        return
      }

      const top = el.offsetTop
      const offset = (-1 * window.innerHeight) / 2 + el.clientHeight / 2

      lenis.scrollTo(top, {
        immediate,
        offset,
        duration: 1,
        easing: easeInOutExpo,
      })
    },
    [lenis, router.asPath]
  )

  useEffect(scrollTo, [scrollTo, router.asPath])

  return (
    <>
      <GlobalMetaTags />
      <MainLayout>
        {/* <ProjectsList /> */}
        <CreativeCompass />
        <NextEvents data={eventsList} />
        <PastEvents data={pastEventsPostList} />
        <ShopFeatured data={featuredProducts} />
        <AboutSection />
        <NewsletterSignupSection />
      </MainLayout>
    </>
  )
}

export async function getStaticProps() {
  const featuredProducts = await getFeaturedProducts()
  const eventsList = await getEventsList()
  const pastEventsPostList = await getEventPostPreviewsByBlogHandle()

  return {
    props: {
      featuredProducts,
      eventsList,
      pastEventsPostList,
    },
    revalidate: 120,
  }
}
