import { AnimatePresence, Variants, m } from 'framer-motion'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { createElement, useState } from 'react'

const SignupForm = dynamic(() => import('@components/signupForm'), {
  ssr: false,
})
const ScCanvas = dynamic(
  () => import('@components/projects/creativeCompass/canvas'),
  { ssr: false }
)

const PROJECT_NAME = 'Creative Compass'

export default function CreativeCompass() {
  const [step, setStep] = useState(0)

  function goNext() {
    setStep(1)
  }

  const CurrentStepComponent = STEPS[step]

  return (
    <AnimatePresence mode="wait">
      <m.div
        className="isolate relative lg:min-h-screen bg-balck lg:flex justify-end"
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <>
          <m.div
            id="creativecompass-canvas"
            className="relative h-[50vh] w-full lg:h-auto lg:min-h-0"
            variants={canvasVariants}
          >
            <ScCanvas />
          </m.div>

          <m.div
            layout
            className="z-10 p-2 h-full shrink-0 lg:max-w-xl select-none bg-background text-foreground border-t-2 lg:border-t-none lg:border-l-2 border-foreground"
            variants={boxVariants}
          >
            <div className="sticky top-16">
              {createElement(CurrentStepComponent.component, {
                goNext,
                ...CurrentStepComponent.props,
              })}
            </div>
          </m.div>
        </>
      </m.div>
    </AnimatePresence>
  )
}

const PendingState = ({ goNext }) => {
  return (
    <div>
      <m.div
        key={1}
        className="relative overflow-hidden"
        custom={1}
        variants={animatedVariants}
        // animate={{ height: 'auto' }}
      >
        <m.h1 className="text-4xl md:text-5xl mb-2">The Creative Compass</m.h1>
        <m.p className="text-lg mb-3">
          Defragmenting Local Creative Culture on a Global Perspective
        </m.p>
        <m.p className="mb-3">
          The new annual publication by CAPSLOCK Magazine intends to investigate
          the role of creative arts in societal development across nations,
          offering a detailed interplay analysis of the two. Each issue curates
          a mosaic of perspectives of 12 artists based in the same country, but
          coming from six distinct creative domains: graphic design,
          photography, artificial intelligence (AI), interior design, fashion
          design, and music.
        </m.p>
        <m.p className="mb-3">
          Stay up to date and be prepared for the launch.
        </m.p>
        {/* <m.ul className="mb-3 list-inside list-disc">
          <m.li>
            Your email - so that we can first inform you of the launch and the
            release of special rewards for our community.
          </m.li>
        </m.ul> */}
      </m.div>
      <m.div
        key={2}
        custom={2}
        variants={animatedVariants}
        className="bg-background border-white border-opacity-20"
      >
        <SignupForm
          theme="light"
          route="/api/newsletter"
          ctaLabel="Subscribe to the newsletter"
          onSuccess={goNext}
        />
      </m.div>
    </div>
  )
}

const SuccessState = () => (
  <>
    <m.h1 className="text-4xl md:text-5xl mb-2">You&apos;re all set</m.h1>
    <m.p variants={animatedVariants} className="mb-3">
      Thank you for your interest in {PROJECT_NAME}.
    </m.p>
    <m.p variants={animatedVariants} className="mb-3">
      You&apos;ve successfully subscribed to our newsletter. Be sure to visit
      our shop for some goodies, and our Instagram page for some hype.
    </m.p>
    <m.div variants={animatedVariants} className="mt-6">
      <Link
        href="https://shop.capslockmagazine.eu"
        target="_blank"
        rel="noopener noreferrer"
        className="text-center block w-full my-2 py-3 bg-foreground text-background border-2 border-black hover:cursor-pointer active:bg-gray active:text-foreground"
      >
        Visit our shop
      </Link>
      <m.a
        layout
        href="https://instagram.com/capslock_mag"
        target="_blank"
        rel="noopener noreferrer"
        className="text-center block w-full my-2 py-3 bg-background text-foreground border-2 border-black hover:cursor-pointer active:bg-gray"
      >
        Visit our instagram page
      </m.a>
    </m.div>
  </>
)

const STEPS = {
  0: { component: PendingState, props: [] },
  1: { component: SuccessState, props: [] },
}

const boxVariants: Variants = {
  initial: {
    opacity: 1,
    height: 'auto',
    // y: -40,
  },
  // animate: {
  //   opacity: 1,
  //   height: 'auto',
  //   y: 0,
  //   transition: {
  //     type: 'ease',
  //     ease: [0.6, 0, 0, 0.9],
  //     duration: 1.2,
  //     delay: 0,
  //     when: 'beforeChildren',
  //   },
  // },
  // exit: { opacity: 0, y: -40, filter: 'blur(20px)' },
}

const animatedVariants: Variants = {
  // initial: { opacity: 0, y: 100, filter: 'blur(20px)' },
  // animate: i => ({
  //   opacity: 1,
  //   y: 0,
  //   filter: 'blur(0px)',
  //   transition: {
  //     type: 'spring',
  //     stiffness: 36,
  //     damping: 8,
  //     delay: i * 0.1,
  //   },
  // }),
  // exit: { opacity: 0, y: -100, filter: 'blur(20px)' },
}

const canvasVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: 1,
    },
  },
}
