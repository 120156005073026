import { LiveEvent } from '@type/shopify/blog'

const NextEvents = ({ data }) => {
  return (
    <div>
      <div className="p-2 border-y-px bg-foreground text-background">
        <span className="text-2xl md:text-4xl">
          Current and upcoming events
        </span>
      </div>
      <div className="overflow-hidden">
        {data.map((e, i) => (
          <Event key={i} data={e} next={i === 0} />
        ))}
      </div>
    </div>
  )
}

function formatDate(date: string) {
  const _date = new Date(date)

  return _date.toLocaleString('en-GB', { dateStyle: 'medium' })
}

function checkHappeningNow(start: string, end: string) {
  const currentTime = new Date().getTime()
  const startTime = new Date(start).getTime()

  if (!end) {
    return false
  }

  const endTime = new Date(end).getTime()

  if (currentTime > startTime && currentTime < endTime) return true
}

const Event = ({ data, next }: { data: LiveEvent; next: boolean }) => {
  const { name, location, start_date, end_date, url } = data

  const startFormatted = formatDate(start_date.value)
  const endFormatted = formatDate(end_date?.value)

  const isHappeningNow = checkHappeningNow(start_date.value, end_date?.value)

  return (
    <a
      href={url.value}
      rel="noopener noreferrer"
      target="_blank"
      className="relative block p-2 first:border-t-none border-t-px"
    >
      <div className="flex items-end">
        <div className="md:flex items-baseline flex-wrap">
          <span className="text-5xl md:text-9xl md:tracking-tighter block">
            {name.value}
          </span>
          {isHappeningNow && (
            <span className="tracking-wide px-1 animate-pulse bg-branding text-black">
              Happening now
            </span>
          )}
          {next && !isHappeningNow && (
            <span className="tracking-wide px-1 bg-branding text-black">
              Next up
            </span>
          )}
        </div>
        <span className="text-5xl md:text-9xl md:tracking-tighter block">
          ↗
        </span>
      </div>
      {/* <div className="text-9xl">
        <span>{name.value}</span>
        <span className="mx-4">-</span>
        <span>{location.value}</span>
      </div> */}
      <div className="mr-2 md:text-4xl flex justify-between my-2">
        <div className="flex">
          <span>{startFormatted}</span>
          {end_date && (
            <>
              <span className="mx-2">→</span>
              <span>{endFormatted}</span>
            </>
          )}
        </div>
        <span>{location.value}</span>
      </div>
    </a>
  )
}

export default NextEvents
